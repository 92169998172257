.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-link {
  color: #09d3ac;
}

.heading {
  color: black;
}

.page-content {
  padding-left: 20px;
  padding-right: 10px;
  padding-top: 90px;
  padding-bottom: 120px;
}

.title {
  font-size: 60px;
  line-height: normal;
  font-family: "Google Sans Regular";
  text-align: center;
  color: rgb(212, 175, 55);
}

.subTitle {
  color: #868e96;
}

/* Animations */
@keyframes fade_down {
  0% {
    opacity: 0.01;
    transform: translateY(-20px);
    transition: opacity 300ms cubic-bezier(0.645, 0.045, 0.355, 1),
      transform 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
    transition: opacity 300ms cubic-bezier(0.645, 0.045, 0.355, 1),
      transform 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
  }
}

@keyframes fade_up {
  0% {
    opacity: 0.01;
    transform: translateY(20px);
    transition: opacity 300ms cubic-bezier(0.645, 0.045, 0.355, 1),
      transform 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
    transition: opacity 300ms cubic-bezier(0.645, 0.045, 0.355, 1),
      transform 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
  }
}
